<template>
  <div>
    <section class="mt-40">
      <div class="container">
        <div class="row">
          <div class="col">
            <div>
              <CountryTab :countries="countries"/>
              <div class="tab-content mt-50">
                <div id="tab-1"
                     :class="{ active: activeTab === 'ALL' }"
                     class="tab-pane fade show" role="tabpanel">
                  <all-country-tab-content/>
                </div>
                <div
                  v-for="country in countries" :id="'country-tab-' + country.id"
                  :key="country.id"
                  :class="{ active: activeTab === country.public_code }" class="tab-pane fade show" role="tabpanel">
                  <each-country-tab-content :key="country.id" :country="country"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import CountryTab from '@/components/common/tab/CountryTab'
import UtilityMixin from '@/mixins/utility-mixin'
import EachCountryTabContent from '@/components/user/regions-farmers-groups/EachCountryTabContent'
import AllCountryTabContent from '@/components/user/regions-farmers-groups/AllCountryTabContent'
import _ from 'lodash'

export default {
  name: 'RegionsFarmersGroups',
  mixins: [UtilityMixin],
  components: {
    EachCountryTabContent,
    AllCountryTabContent,
    CountryTab
  },
  data () {
    return {
      loading: false,
      activeTab: 'ALL',
      regionsLocationApiCalled: false
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  async created () {
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'ALL'
  },
  methods: {
    async getRegionFGLocations () {
    }
  },
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'ALL'
    }
  }
}
</script>

<style scoped>

</style>
