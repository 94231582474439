<template>
  <regions-farmers-groups></regions-farmers-groups>
</template>

<script>

import RegionsFarmersGroups from '@/components/user/regions-farmers-groups/RegionsFarmersGroups'

export default {
  name: 'RegionsFarmersGroupsView',
  components: { RegionsFarmersGroups }
}
</script>

<style scoped>

</style>
