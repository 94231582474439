<template>
  <div>
    <h3 class="text-primary mb-30">{{ title }}</h3>
    <div class="row">
      <div class="col-lg-9">
        <div v-show="mapContentLoading" class="progress" style="top: 50% !important;position:relative;">
          <div :style="{width: mapLoadingPercentage+'%'}" aria-valuemax="100" aria-valuemin="0"
               aria-valuenow="75" class="progress-bar progress-bar-striped progress-bar-animated"
               role="progressbar">{{ mapLoadingPercentage }}%
          </div>
        </div>
        <content-loader
          v-show="mapContentLoading"
          :height="250"
          :speed="1"
          :width="476"
          primaryColor="#ede9e9"
          secondaryColor="#ecebeb"
        >
          <rect height="250" rx="5" ry="5" width="500" x="0" y="0"/>
        </content-loader>
        <MapChart
          v-if="!mapContentLoading"
          :enabled-map-legends="enabledMapLegends"
          :geo-positions="geoPositions"
          :map-center="mapCenter"
          :map-zoom-level="2"
        />
        <div v-show="geoPositions.length === 0 && !mapContentLoading">Map data aren't available</div>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-3">
        <div v-show="keyStatsLoading" class="card icon-box mb-3 card-service">
          <div class="card-body align-items-center d-block">
            <content-loader
              :height="100"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="30" rx="3" ry="3" width="50%" x="80" y="10"/>
              <rect height="30" rx="3" ry="3" width="100%" x="80" y="60"/>
              <rect height="50" rx="0" ry="0" width="50" x="-5" y="15"/>
            </content-loader>
          </div>
        </div>
        <div v-show="keyStatsLoading" class="card icon-box mb-3 card-service">
          <div class="card-body align-items-center d-block">
            <content-loader
              :height="100"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="30" rx="3" ry="3" width="50%" x="80" y="10"/>
              <rect height="30" rx="3" ry="3" width="100%" x="80" y="60"/>
              <rect height="50" rx="0" ry="0" width="50" x="-5" y="15"/>
            </content-loader>
          </div>
        </div>
        <div v-show="keyStatsLoading" class="card icon-box mb-3 card-service">
          <div class="card-body align-items-center d-block">
            <content-loader
              :height="100"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="30" rx="3" ry="3" width="50%" x="80" y="10"/>
              <rect height="30" rx="3" ry="3" width="100%" x="80" y="60"/>
              <rect height="50" rx="0" ry="0" width="50" x="-5" y="15"/>
            </content-loader>
          </div>
        </div>
        <small-card-with-icon
          v-for="(item, _i) in keyStatistics"
          v-show="!keyStatsLoading"
          :key="_i"
          :icon-name="item.icon ? item.icon : ''"
          :subtitle="item.title"
          :value="item.subtitle"/>
      </div>
    </div>
    <FarmerSubGroupTable></FarmerSubGroupTable>
  </div>
</template>

<script>

import SmallCardWithIcon from '@/components/common/SmallCardWithIcon'
import FarmerSubGroupTable from '@/components/user/regions-farmers-groups/FarmerSubGroupTable'
import MapChart from '@/components/common/MapChart'
import _ from 'lodash'
import { ContentLoader } from 'vue-content-loader'
import CommonUtils from '@/utils/common'
import APIHelper from '@/service/api-helper'
import ServerConfig from '@/config/server'

export default {
  name: 'AllCountryTabContent',
  components: {
    MapChart,
    SmallCardWithIcon,
    FarmerSubGroupTable,
    ContentLoader
  },
  data () {
    return {
      title: 'Regions and farmer groups/labels',
      mapCenter: { lat: 22.097593, lng: -18.8835427 },
      geoPositions: [],
      regionFgSg: [],
      enabledMapLegends: {
        region: {
          name: 'Region',
          icon: '006330.svg'
        },
        farmerGroup: {
          name: 'Farmer Groups/Labels',
          icon: '69AA1A.svg'
        },
        farmerSubGroup: {
          name: 'Farmer Subgroups',
          icon: 'ED1C24.svg'
        }
      },
      mapContentLoading: true,
      keyStatsLoading: true,
      keyStatistics: [],
      mapLoadingPercentage: 0
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  methods: {
    async getRegionAndFarmersGroupMapPosition () {
      const regionFarmerGroups = this.$store.getters.getRegionFarmerGroupLocationContents
      this.geoPositions = []
      for (const _data of regionFarmerGroups) {
        let _tempContainer = {}

        const regionGps = _.get(_data, 'region.location') || null
        const groupGps = _.get(_data, 'group.location') || null
        const subGroupGps = _.get(_data, 'sub_group.location') || null

        const regionName = _.get(_data, 'region_name') || 'N/A'
        const regionPk = _.get(_data, 'id') || 0
        const groupName = _.get(_data, 'group_name') || 'N/A'
        const groupPk = _.get(_data, 'id') || 0
        const subGroupName = _.get(_data, 'sub_group_name') || 'N/A'
        const subGroupPk = _.get(_data, 'id') || 0

        let regionLink = ''
        if (regionPk) {
          regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
        }

        let groupLink = ''
        if (groupPk) {
          groupLink = `<a href='/farmers-region-group/detail/${groupPk}/group/'>${groupName}</a>`
        }

        let subGroupLink = ''
        if (subGroupPk) {
          subGroupLink = `<a href='/farmers-region-group/detail/${subGroupPk}/sub-group/'>${subGroupName}</a>`
        }

        // Set Map Center
        if (_.isEmpty(this.mapCenter)) {
          let lat = 0
          let lng = 0
          if (!_.isEmpty(regionGps) && _.isEmpty(this.mapCenter)) {
            lat = regionGps.latitude || 0
            lng = regionGps.longitude || 0
          } else if (!_.isEmpty(groupGps) && _.isEmpty(this.mapCenter)) {
            lat = groupGps.latitude || 0
            lng = groupGps.longitude || 0
          } else if (!_.isEmpty(subGroupGps) && _.isEmpty(this.mapCenter)) {
            lat = subGroupGps.latitude || 0
            lng = subGroupGps.longitude || 0
          }
          if (!_.isEmpty(this.mapCenter && (lat || lng))) {
            this.mapCenter.lat = parseFloat(lat)
            this.mapCenter.lng = parseFloat(lng)
          }
        }
        // Region GPS
        if (!_.isEmpty(regionGps)) {
          const lat = regionGps.latitude || 0
          const lng = regionGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Region',
            description: regionLink || regionName,
            type: 'region'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Group GPS
        if (!_.isEmpty(groupGps)) {
          const lat = groupGps.latitude || 0
          const lng = groupGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Group',
            description: groupLink || groupName,
            type: 'farmerGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
        // Sub Group GPS
        if (!_.isEmpty(subGroupGps)) {
          const lat = subGroupGps.latitude || 0
          const lng = subGroupGps.longitude || 0
          _tempContainer = {
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            },
            name: 'Subgroup',
            description: subGroupLink || subGroupName,
            type: 'farmerSubGroup'
          }

          this.geoPositions.push(_tempContainer)
        }
      }
      if (_.isEmpty(this.mapCenter)) {
        this.mapCenter.lat = 14.769611
        this.mapCenter.lng = 32.508846
      }
    },
    async getRegionAndFarmersGroupKeyStats () {
      this.loading = true
      const response = await this.$store.dispatch('getUserRegionFgSg')
      const result = _.get(response, 'data') || {}
      const isAdminOrExporter = ['SystemAdmin', 'Admin', 'NKG Admin', 'Exporter', 'Observer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name')) || false

      let regionTitle = ''
      if (isAdminOrExporter) {
        regionTitle = 'NKG Bloom regions'
      } else {
        // https://redmine.field.buzz/issues/20589
        regionTitle = 'NKG BLOOM regions'
        // regionTitle = 'Regions you source from'
      }

      let groupTitle = ''
      if (isAdminOrExporter) {
        groupTitle = 'NKG Bloom farmer groups/labels'
      } else {
        // https://redmine.field.buzz/issues/20589
        groupTitle = 'NKG BLOOM farmer groups/labels'
        // groupTitle = 'Farmer groups/labels you source from'
      }

      let subGroupTitle = ''
      if (isAdminOrExporter) {
        subGroupTitle = 'NKG Bloom farmer subgroups'
      } else {
        // https://redmine.field.buzz/issues/20589
        subGroupTitle = 'NKG BLOOM farmer subgroups'
        // subGroupTitle = 'Farmer subgroups you source from'
      }

      const regionObj = {
        icon: 'icon_Region.svg',
        title: regionTitle,
        subtitle: _.get(result, 'total_region') || 0,
        data_unit: 'mt'
      }
      const groupObj = {
        icon: 'farmer-groups.svg',
        title: groupTitle,
        subtitle: _.get(result, 'total_group') || 0,
        data_unit: 'mt'
      }
      const subGroupObj = {
        icon: 'icon-subgroups.svg',
        title: subGroupTitle,
        subtitle: _.get(result, 'total_subgroup') || 0,
        data_unit: 'mt'
      }
      this.keyStatistics = [regionObj, groupObj, subGroupObj]
      this.loading = false
    },
    async get () {
      const response = await APIHelper.getData(this.apiUrl)
      const data = response.data
      if (data.success) {
        const totalPage = _.get(data, 'total_page')
        const completedPercentage = Math.ceil(this.mapLoadingPercentage + (100 / totalPage))
        if (completedPercentage > 100) {
          this.mapLoadingPercentage = 100
        } else {
          this.mapLoadingPercentage = completedPercentage
        }
        this.data = [...this.data, ...data.results]
        if (data.next !== null) {
          this.apiUrl = ServerConfig.serverUrl + data.next
          return this.get()
        }
      } else {
        this.errors.push(data)
      }
    },
    async getLocationsDataFromApi (apiUrl) {
      this.limit = 10
      this.offset = 0 // For the first time
      const params = {
        ...{
          limit: this.limit,
          offset: this.offset
        }
      }
      this.apiUrl = CommonUtils.makeApiUrl(apiUrl, params)
      this.data = []
      this.errors = []

      // eslint-disable-next-line no-async-promise-executor
      return await new Promise(async (resolve, reject) => {
        await this.get()
        if (this.errors.length > 0) {
          reject(this.errors)
        }
        resolve(this.data)
      })
    },
    async getMapLocations () {
      this.mapContentLoading = true

      const regionLocations = await this.getLocationsDataFromApi('v3/region-meta')
      const groupLocations = await this.getLocationsDataFromApi('v3/group-meta')
      const subGroupLocations = await this.getLocationsDataFromApi('v3/sub-group-meta')

      this.$store.commit('setRegionFarmerGroupLocationContent', [...regionLocations, ...groupLocations, ...subGroupLocations])
      await this.getRegionAndFarmersGroupMapPosition()
      this.mapContentLoading = false
    }
  },
  async created () {
    this.keyStatsLoading = true
    await this.getRegionAndFarmersGroupKeyStats()
    this.keyStatsLoading = false
    await this.getMapLocations()
  }
}
</script>

<style scoped>
.progress {
  background-color: #f1f1f1;
}
</style>
