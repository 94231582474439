<template>
  <div>
    <div class="row table-wrapper px-20">
      <div class="content-heading d-flex justify-content-between align-items-center">
        <div class="d-lg-flex d-md-block d-block mt-md-10">
          <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
            <button id="tableTypeDropDown" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                    data-toggle="dropdown" type="button">
              {{ tableType.tableTitle || 'Select Table Type' }}
            </button>
            <div aria-labelledby="tableTypeDropDown" class="dropdown-menu"
                 style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                 x-placement="bottom-start">
              <a v-for="(tableType, _index) in tableTypes" :key="_index"
                 class="dropdown-item"
                 @click="selectTableType(tableType)">{{ tableType.header }}</a>
            </div>
          </div>
        </div>
        <div class="d-lg-flex d-md-block d-block mt-md-10">
          <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
            <button id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                    data-toggle="dropdown" type="button">
              {{ searchDict.searchField.header || 'Select column' }}
            </button>
            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu"
                 style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                 x-placement="bottom-start">
              <a v-show="searchDict.searchField" class="dropdown-item" @click="selectColumn('')">Select column</a>
              <a v-for="(searchConfig,_index) in tableSearchConfig" :key="_index"
                 class="dropdown-item"
                 @click="selectColumn(searchConfig)">{{ searchConfig.header }}</a>
            </div>
          </div>
          <div class="search-form">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'text'"
                   v-model.trim="searchDict.searchText"
                   class="form-control"
                   placeholder="Search" type="text" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                   v-model.trim="searchDict.fromDate"
                   class="form-control mr-2" type="date" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                   v-model.trim="searchDict.toDate"
                   class="form-control"
                   type="date" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number'"
                   v-model.trim="searchDict.searchText"
                   class="form-control"
                   placeholder="Search" type="number" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                   v-model.trim="searchDict.fromNumber"
                   class="form-control mr-2" placeholder="From" type="number" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                   v-model.trim="searchDict.toNumber"
                   class="form-control" placeholder="To" type="number" @keyup.enter="applySearch()">
            <input v-show="!searchDict.searchField" v-model.trim="searchDict.searchText"
                   class="form-control" placeholder="Search" type="text" @keyup.enter="applySearch()">
            <div>
              <button class="btn btn-primary" @click="applySearch()">
                <img alt="Search" src="@/assets/img/icons/search-glass.svg">
              </button>
              <button class="btn btn-outline-primary ml-2" @click="clearSearch()">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th v-for="(item, _i) in tableHeaders" :key="_i"
              @click="applyOrdering(item.db_representation, item.orderColumn)">
            <span class="pr-1">
              {{ item.title }} <br> {{ item.subtitle }}
            </span>
            <span
              v-show="orderColumnDbRepresentation === item.db_representation"
              :class="[orderBy === '-' ? 'descending': 'ascending']">
            </span>
          </th>
        </tr>
        </thead>
        <tbody v-show="loading">
        <tr>
          <td :colspan="tableHeaders.length">
            <FBTableLoader :columns="5" :height="300" :primaryColor="'#d3d3d3'" :rows="5"
                           class="col-12"></FBTableLoader>
          </td>
        </tr>
        </tbody>
        <tbody v-show="!loading">
        <tr v-for="(rowData, _rowIndex) in tableData" :key="_rowIndex">
          <td v-for="(item, _i) in rowData" :key="_i"><span v-html="item"></span></td>
        </tr>
        <tr v-if="tableData.length === 0">
          <td :colspan="tableHeaders.length">
            No data available
          </td>
        </tr>
        </tbody>
      </table>
      <div class="container pl-0 pr-0">
        <div class="">
          <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
            <div>
              <select v-model="paginateDict.dataPerPage" class="form-control admin-input justify-content-start"
                      @change="getContentsFromServer()">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <div>
              <nav aria-label="Page navigation example">
                <ul class="pagination pagination-lg justify-content-end">
                  <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                  </li>
                  <li v-show="paginateDict.previousPageNumber > 0"
                      :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                    <a class="page-link"
                       @click="getPaginatedData(paginateDict.previousUrl)">{{ paginateDict.previousPageNumber }}</a>
                  </li>
                  <li class="page-item disabled"><a class="page-link" href="#">{{ paginateDict.currentPageNumber }}</a>
                  </li>
                  <li v-show="paginateDict.nextPageNumber > 0"
                      :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                        paginateDict.nextPageNumber
                      }}</a>
                  </li>
                  <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UtilityMixin from '@/mixins/utility-mixin'
import _ from 'lodash'
import { FBTableLoader } from '@/plugins/content-loader/core/components'

export default {
  name: 'FarmerSubGroupTable',
  props: {
    country: {
      required: false,
      default: function () {
        return null
      },
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      country_filter_param: {
        region: 'region__parent__public_code',
        group: 'group__parent__parent__public_code',
        sub_group: 'group__parent__parent__parent__public_code'
      },
      tableTypes: [
        {
          header: 'Region',
          key: 'region',
          widget_type: 'text',
          url: 'v1/region-meta'
        },
        {
          header: 'Farmer Groups/Labels',
          key: 'group',
          widget_type: 'text',
          url: 'v1/group-meta'
        },
        {
          header: 'Farmer Subgroups',
          key: 'sub_group',
          widget_type: 'text',
          url: 'v1/sub-group-meta'
        }
      ],
      regionHeaders: [
        {
          title: '',
          subtitle: 'Country',
          db_representation: 'country', // user for apply sort
          orderColumn: 'country.name' // apply ordering js level
        },
        {
          title: '',
          subtitle: 'Regions',
          db_representation: 'region',
          orderColumn: 'region.name'
        },
        {
          title: 'Number',
          subtitle: 'of farmers',
          db_representation: 'farmers_total',
          orderColumn: 'farmers_total'
        },
        {
          title: 'Altitude',
          subtitle: 'masl',
          db_representation: 'altitude_min',
          orderColumn: 'altitude_min'
        },
        {
          title: 'Coffee',
          subtitle: 'Species',
          db_representation: 'coffee_species',
          orderColumn: 'coffee_species'
        },
        {
          title: '',
          subtitle: 'Harvest Period (main)',
          db_representation: 'main_start_date',
          orderColumn: 'main_start_date'
        },
        {
          title: 'Harvest',
          subtitle: 'Period (fly)',
          db_representation: 'fly_start_date',
          orderColumn: 'fly_start_date'
        },
        {
          title: '',
          subtitle: 'Qualities',
          db_representation: 'qualities',
          orderColumn: 'qualities'
        }
      ],
      groupHeaders: [
        {
          title: '',
          subtitle: 'Country',
          db_representation: 'country',
          orderColumn: 'country.name'
        },
        {
          title: '',
          subtitle: 'Regions',
          db_representation: 'region',
          orderColumn: 'region.name'
        },
        {
          title: 'Farmer',
          subtitle: 'Groups/Labels',
          db_representation: 'group',
          orderColumn: 'group.name'
        },
        {
          title: 'Number',
          subtitle: 'of farmers',
          db_representation: 'farmers_total',
          orderColumn: 'farmers_total'
        },
        {
          title: 'Altitude',
          subtitle: 'masl',
          db_representation: 'altitude_min',
          orderColumn: 'altitude_min'
        },
        {
          title: 'Coffee',
          subtitle: 'Species',
          db_representation: 'coffee_species',
          orderColumn: 'coffee_species'
        },
        {
          title: '',
          subtitle: 'Harvest Period (main)',
          db_representation: 'main_start_date',
          orderColumn: 'main_start_date'
        },
        {
          title: 'Harvest',
          subtitle: 'Period (fly)',
          db_representation: 'fly_start_date',
          orderColumn: 'fly_start_date'
        },
        {
          title: '',
          subtitle: 'Qualities',
          db_representation: 'qualities',
          orderColumn: 'qualities'
        }
      ],
      subGroupHeaders: [
        {
          title: '',
          subtitle: 'Country',
          db_representation: 'country',
          orderColumn: 'country'
        },
        {
          title: '',
          subtitle: 'Regions',
          db_representation: 'region',
          orderColumn: 'region'
        },
        {
          title: 'Farmer',
          subtitle: 'Groups/Labels',
          db_representation: 'parent_group', // This is actually group
          orderColumn: 'parent_group'
        },
        {
          title: 'Factories/',
          subtitle: 'Subgroups',
          db_representation: 'group',
          orderColumn: 'group'
        },
        {
          title: 'Number',
          subtitle: 'of farmers',
          db_representation: 'farmers_total',
          orderColumn: 'farmers_total'
        },
        {
          title: 'Altitude',
          subtitle: 'masl',
          db_representation: 'altitude_min',
          orderColumn: 'altitude_min'
        },
        {
          title: 'Coffee',
          subtitle: 'Species',
          db_representation: 'coffee_species',
          orderColumn: 'coffee_species'
        },
        {
          title: '',
          subtitle: 'Harvest Period (main)',
          db_representation: 'main_start_date',
          orderColumn: 'main_start_date'
        },
        {
          title: 'Harvest',
          subtitle: 'Period (fly)',
          db_representation: 'fly_start_date',
          orderColumn: 'fly_start_date'
        },
        {
          title: '',
          subtitle: 'Qualities',
          db_representation: 'qualities',
          orderColumn: 'qualities'
        }
      ],
      tableHeaders: [],
      regionSearchConfig: [
        {
          header: 'Country',
          db_representation: 'region__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Region',
          db_representation: 'region__name',
          widget_type: 'text'
        },
        {
          header: 'Number of Farmers',
          db_representation: 'farmers_total',
          widget_type: 'number'
        },
        {
          header: 'Altitude masl',
          db_representation: 'altitude_min,altitude_max',
          widget_type: 'number_range'
        },
        {
          header: 'Coffee Species',
          db_representation: 'coffee_species__name',
          widget_type: 'text'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'main_start_date,main_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'fly_start_date,fly_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Qualities',
          db_representation: 'qualities__name',
          widget_type: 'text'
        }
      ],
      groupSearchConfig: [
        {
          header: 'Country',
          db_representation: 'group__parent__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Region',
          db_representation: 'group__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Group/Label',
          db_representation: 'group__name',
          widget_type: 'text'
        },
        {
          header: 'Number of Farmers',
          db_representation: 'farmers_total',
          widget_type: 'number'
        },
        {
          header: 'Altitude masl',
          db_representation: 'altitude_min,altitude_max',
          widget_type: 'number_range'
        },
        {
          header: 'Coffee Species',
          db_representation: 'coffee_species__name',
          widget_type: 'text'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'main_start_date,main_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'fly_start_date,fly_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Qualities',
          db_representation: 'qualities__name',
          widget_type: 'text'
        }
      ],
      subGroupSearchConfig: [
        {
          header: 'Country',
          db_representation: 'group__parent__parent__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Region',
          db_representation: 'group__parent__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Group/Label',
          db_representation: 'group__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Subgroup',
          db_representation: 'group__name',
          widget_type: 'text'
        },
        {
          header: 'Number of Farmers',
          db_representation: 'farmers_total',
          widget_type: 'number'
        },
        {
          header: 'Altitude masl',
          db_representation: 'altitude_min,altitude_max',
          widget_type: 'number_range'
        },
        {
          header: 'Coffee Species',
          db_representation: 'coffee_species__name',
          widget_type: 'text'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'main_start_date,main_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'fly_start_date,fly_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Qualities',
          db_representation: 'qualities__name',
          widget_type: 'text'
        }
      ],
      tableSearchConfig: [],
      tableData: [],
      tableType: {
        tableTitle: '',
        tableKey: ''
      },
      searchDict: {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      apiPrefix: 'v1/sub-group-meta'
    }
  },
  components: { FBTableLoader },
  mixins: [validationMixin, UtilityMixin],
  validations: {
    searchDict: {
      searchField: {
        required
      }
    }
  },
  methods: {
    selectTableType (tableType) {
      if (this.tableType.tableKey === tableType.key) {
        return
      }
      this.tableType.tableTitle = tableType.header
      this.tableType.tableKey = tableType.key
      this.apiPrefix = tableType.url
      if (tableType.key === 'region') {
        this.tableHeaders = this.regionHeaders
        if (this.country !== null && this.country !== undefined) {
          this.tableSearchConfig = this.regionSearchConfig.slice(1)
        } else {
          this.tableSearchConfig = this.regionSearchConfig
        }
      } else if (tableType.key === 'group') {
        this.tableHeaders = this.groupHeaders
        if (this.country !== null && this.country !== undefined) {
          this.tableSearchConfig = this.groupSearchConfig.slice(1)
        } else {
          this.tableSearchConfig = this.groupSearchConfig
        }
      } else {
        this.tableHeaders = this.subGroupHeaders
        if (this.country !== null && this.country !== undefined) {
          this.tableSearchConfig = this.subGroupSearchConfig.slice(1)
        } else {
          this.tableSearchConfig = this.subGroupSearchConfig
        }
      }
      this.clearSearch()
    },
    selectColumn (searchConfig) {
      this.searchDict = {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.searchDict.searchField = searchConfig
    },
    clearSearch () {
      this.searchDict = {
        searchField: '',
        searchText: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.getContentsFromServer()
    },
    prepareTableContents () {
      try {
        this.tableData = []
        let _content = this.$store.getters.getRegionFarmerGroupAllCountrySubGroupContents
        const orderBy = this.orderBy === '-' ? 'desc' : 'asc'
        if (!_.includes(['coffee_species', 'qualities'], this.orderColumn)) {
          _content = _.orderBy(_content, [this.orderColumn], [orderBy])
        }
        // if (this.orderColumn === 'fly_start_date' && orderBy === '') {
        //   _content = _.sortBy(_content, ['fly_start_date'])
        // }
        _content = _.uniqWith(_content, _.isEqual)
        for (const _data of _content) {
          const tempData = []
          tempData.push(_data.country_name)
          if (this.tableType.tableKey === 'region') {
            tempData.push('<a href="/farmers-region-group/detail/' + _data.id + '/region/">' + _data.region_name + '</a>')
          } else if (this.tableType.tableKey === 'group') {
            const regionName = _.get(_data, 'region_meta.region_name') || 'N/A'
            const regionPk = _.get(_data, 'region_meta.id') || 0
            let regionLink = ''
            if (regionPk) {
              regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
            }
            tempData.push(regionLink || regionName)
            tempData.push('<a href="/farmers-region-group/detail/' + _data.id + '/group/">' + _data.group_name + '</a>')
          } else if (this.tableType.tableKey === 'sub_group') {
            const regionName = _.get(_data, 'region_meta.region_name') || 'N/A'
            const regionPk = _.get(_data, 'region_meta.id') || 0
            const groupName = _.get(_data, 'group_meta.group_name') || 'N/A'
            const groupPk = _.get(_data, 'group_meta.id') || 0

            let regionLink = ''
            if (regionPk) {
              regionLink = `<a href='/farmers-region-group/detail/${regionPk}/region/'>${regionName}</a>`
            }

            let groupLink = ''
            if (groupPk) {
              groupLink = `<a href='/farmers-region-group/detail/${groupPk}/group/'>${groupName}</a>`
            }
            tempData.push(regionLink || regionName)
            tempData.push(groupLink || groupName)
            tempData.push('<a href="/farmers-region-group/detail/' + _data.id + '/sub-group/">' + _data.sub_group_name + '</a>')
          }

          let farmersTotal = _.get(_data, 'farmers_total') || 0
          if (typeof farmersTotal === 'number') {
            farmersTotal = farmersTotal.toLocaleString()
          } else {
            farmersTotal = Number(farmersTotal).toLocaleString()
          }
          tempData.push(farmersTotal)

          let altitudeMax = _.get(_data, 'altitude_max') || 0
          if (typeof altitudeMax === 'number') {
            altitudeMax = altitudeMax.toLocaleString()
          } else {
            altitudeMax = Number(altitudeMax).toLocaleString()
          }

          let altitudeMin = _.get(_data, 'altitude_min') || 0
          if (typeof altitudeMin === 'number') {
            altitudeMin = altitudeMin.toLocaleString()
          } else {
            altitudeMin = Number(altitudeMin).toLocaleString()
          }
          tempData.push(altitudeMin + ' - ' + altitudeMax)
          let coffeeSpecies = []
          coffeeSpecies = _.orderBy(_data.coffee_species, ['name'], [orderBy])
          coffeeSpecies = coffeeSpecies ? coffeeSpecies.map(value => value.name).join(', ') : ''
          coffeeSpecies = coffeeSpecies || 'N/A'
          tempData.push(coffeeSpecies)

          let mainStart = ''
          let mainEnd = ''
          if (_.get(_data, 'main_start_date')) {
            mainStart = moment(_data.main_start_date).format('DD MMM')
          }
          if (_.get(_data, 'main_end_date')) {
            mainEnd = moment(_data.main_end_date).format('DD MMM')
          }

          let flyStart = ''
          let flyEnd = ''
          if (_.get(_data, 'fly_start_date')) {
            flyStart = moment(_data.fly_start_date).format('DD MMM')
          }
          if (_.get(_data, 'fly_end_date')) {
            flyEnd = moment(_data.fly_end_date).format('DD MMM')
          }

          if (mainStart || mainEnd) {
            tempData.push((mainStart || 'N/A') + ' - ' + (mainEnd || 'N/A'))
          } else {
            tempData.push('N/A')
          }
          if (flyStart || flyEnd) {
            tempData.push((flyStart || 'N/A') + ' - ' + (flyEnd || 'N/A'))
          } else {
            tempData.push('N/A')
          }

          let qualities = []
          qualities = _.orderBy(_data.qualities, ['name'], [orderBy])
          qualities = qualities ? qualities.map(value => value.name).join(', ') : ''
          qualities = qualities || 'N/A'
          tempData.push(qualities)

          this.tableData.push(tempData)
        }
        return this.tableData
      } catch (e) {
        this.errorLog(e)
      }
    },
    generatePagination (data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = `/api/${this.apiPrefix}/?limit=${this.paginateDict.dataPerPage}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        let offset = 0
        for (let i = 1; i < this.paginateDict.totalPage; i++) {
          offset += _.parseInt(this.paginateDict.dataPerPage)
        }
        this.paginateDict.lastPage = `/api/${this.apiPrefix}/?offset=${offset}&limit=${this.paginateDict.dataPerPage}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    getCountryParam (apiUrl) {
      if (this.country === null || this.country === undefined) {
        return ''
      }
      return `${this.country_filter_param[this.tableType.tableKey]}=${this.country.public_code}`
    },
    async applyOrdering (fieldName, orderColumn) {
      if (this.orderBy === '-') {
        this.orderBy = ''
      } else {
        this.orderBy = '-'
      }

      this.orderColumn = orderColumn
      this.orderColumnDbRepresentation = fieldName
      await this.getContentsFromServer()
    },
    async getContentsFromServer () {
      try {
        this.loading = true
        const offset = 'offset=0'
        const limit = `limit=${this.paginateDict.dataPerPage}`
        const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        const response = await this.$store.dispatch('getSubGroupListFromServer', { apiUrl: apiUrl })
        const data = response.data
        if (data.success) {
          this.generatePagination(data)
          this.$store.commit('setRegionFarmerSubGroupContent', data.results)
          await this.prepareTableContents()
        } else {
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData (apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch('getSubGroupListFromServer', { apiUrl: apiUrl })
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit('setRegionFarmerSubGroupContent', data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    async applySearch () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (this.searchDict.searchField.widget_type === 'text' || this.searchDict.searchField.widget_type === 'number') {
          try {
            this.loading = true
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchParams = `${this.searchDict.searchField.db_representation}=${this.searchDict.searchText}`
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getSubGroupListFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setRegionFarmerSubGroupContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
        if (this.searchDict.searchField.widget_type === 'date_range') {
          try {
            this.loading = true
            const fromDate = Date.parse(this.searchDict.fromDate) - 86400000
            const toDate = Date.parse(this.searchDict.toDate) + 86400000
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchKeys = _.split(this.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              searchParams = `${_from}=${fromDate},${toDate}&${_to}=${fromDate},${toDate}`
            }
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getSubGroupListFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setRegionFarmerSubGroupContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
        if (this.searchDict.searchField.widget_type === 'number_range') {
          try {
            this.loading = true
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchKeys = _.split(this.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              searchParams = `${_from}=${this.searchDict.fromNumber},${this.searchDict.toNumber}&${_to}=${this.searchDict.fromNumber},${this.searchDict.toNumber}`
            }
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&${this.getCountryParam()}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getSubGroupListFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setRegionFarmerSubGroupContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
    }
  },
  mounted () {
  },
  async created () {
    this.selectTableType(this.tableTypes[0])
  }
}
</script>

<style lang="scss" scoped>
.custom-dropdown .dropdown-menu a,
.page-link:hover {
  cursor: pointer;
}
</style>
